import { FC } from 'react'
import './styles.css'
import { ApiKeyMetadata, VehicleInfo } from '../../types/api.types'
import { VehiclesGridItem } from '../Vehicles/VehiclesGrid'
import { formattedLocation } from '../../utils/LocationUtil'

interface ApiKeyComponentProps {
  apiKey: ApiKeyMetadata
  isSelected: boolean
  onSelection: (id: string) => void
}

const ApiKeyComponent: FC<ApiKeyComponentProps> = ({ apiKey, isSelected, onSelection }) => {
  return (
    <VehiclesGridItem
      vehicle={{ ...apiKey.vehicle, isPrimaryUser: true, isSupported: true } as VehicleInfo}
      isSelected={isSelected}
      onSelection={() => onSelection(apiKey.id)}
      showRadio={false}
    >
      <span>{apiKey.provider}</span>
      <span>{formattedLocation(apiKey.location)}</span>
    </VehiclesGridItem>
  )
}

export default ApiKeyComponent
