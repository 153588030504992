import React, { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react'
import './styles.css'
import { autocompleteAddress } from '../../../utils/LocationUtil'
import { GeoLocation, LocationWithAddress } from '../../../types/api.types'

const MIN_QUERY_LENGTH = 5

interface SearchInputProps {
  placeholder: string
  setLocation: (location: GeoLocation) => void
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, setLocation }) => {
  const [query, setQuery] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [debouncedQuery, setDebouncedQuery] = useState(query)
  const [suggestions, setSuggestions] = useState<LocationWithAddress[]>([])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query)
    }, 1000)

    return () => {
      clearTimeout(handler)
    }
  }, [query])

  useEffect(() => {
    if (debouncedQuery.length >= MIN_QUERY_LENGTH) {
      const fetchSuggestions = async () => {
        const results = await autocompleteAddress(debouncedQuery)
        setSuggestions(results)
      }
      fetchSuggestions()
    } else {
      setSuggestions([])
    }
  }, [debouncedQuery])

  const handleSelectSuggestion = async (item: LocationWithAddress) => {
    setQuery(item.address)
    setShowSuggestions(false)
    setLocation({ latitude: item.latitude, longitude: item.longitude })
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
    setShowSuggestions(true)
  }

  const onBlur = () => {
    //delayed to avoid hiding suggestion before onClick is registered
    setTimeout(() => {
      setShowSuggestions(false)
    }, 100)
  }

  const handleKeyPress = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && query) {
      const results = await autocompleteAddress(query)
      if (results.length === 1) {
        handleSelectSuggestion(results[0])
      }
    }
  }

  return (
    <div className="search-input-container">
      <input
        type="text"
        className="search-input"
        placeholder={placeholder}
        value={query}
        onChange={onChange}
        onKeyUp={handleKeyPress}
        onBlur={onBlur}
        onFocus={() => setShowSuggestions(true)}
      />
      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSelectSuggestion(suggestion)}>
              {suggestion.address}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default SearchInput
