import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from '../../store'
import { logoutUser } from '../../features/authSlice/authSlice'

import './styles.css'
import BmwGroupLogo from '../../assets/images/bmw-group-logo.svg'
import LogoutIcon from '../../assets/images/logout_rg.svg'

const NavBar: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { isLoggedIn } = useSelector((state: RootState) => state.auth)

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  return (
    <>
      <div className="navbar-component">
        <div className="navbar-left">
          <img className="brand-image" src={BmwGroupLogo} alt="BMW Group Logo" />
        </div>
        <div className="navbar-right">
          {isLoggedIn && (
            <button className="icon-btn" onClick={handleLogout}>
              <img src={LogoutIcon} alt="Logout" />
            </button>
          )}
        </div>
      </div>
      <div className="header-line"></div>
    </>
  )
}

export default NavBar
