import { FC, useEffect, useState } from 'react'
import SearchBar from '../../components/Wizard/SearchBar/SearchBar'
import Map from '../../components/Wizard/Map/Map'
import './styles.css'
import LocationUtil from '../../utils/LocationUtil'
import { useTranslation } from 'react-i18next'
import { GeoLocation } from '../../types/api.types'
import { WizardStepProps } from './WizardConfigurationUtil'
import { FormLabel } from '../../components/Form/FormLabel'

const MAX_ALLOWED_DISTANCE_IN_M = 200

export interface WizardLocationConfiguration {
  location?: GeoLocation
}

const WizardLocationComponent: FC<WizardStepProps<WizardLocationConfiguration>> = ({
  provider,
  configuration,
  setConfiguration,
}) => {
  const { t } = useTranslation()
  const [currentAddress, setCurrentAddress] = useState<string | undefined>()

  useEffect(() => {
    const getLocation = async () => {
      const location = configuration.location
      if (location == null) {
        setCurrentAddress(undefined)
      } else {
        setCurrentAddress(await LocationUtil.addressForLocation(location))
      }
    }
    getLocation()
  }, [configuration.location])

  const setLocation = (location: GeoLocation) => {
    setConfiguration({ location })
  }

  return (
    <>
      <p className="wizard-header">{t('wizard.location.description', { provider })}</p>
      <SearchBar placeholder={t('wizard.location.search')} setLocation={setLocation} />
      <div className="wizard-map-container">
        <Map location={configuration.location} setLocation={setLocation} locationRadius={MAX_ALLOWED_DISTANCE_IN_M} />
      </div>
      <FormLabel
        titleKey={'wizard.location.chosenLocation'}
        value={currentAddress || t('wizard.location.noLocation')}
      />
    </>
  )
}

export default WizardLocationComponent
