import { FC } from 'react'
import './styles.css'
import CloseIcon from '../../../assets/images/close_rg.svg'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Buttons/Button'

interface ConfirmModalProps {
  title?: string
  message?: string
  onConfirm: () => void
  onClose: () => void
}

const ConfirmModal: FC<ConfirmModalProps> = ({ title, message, onConfirm, onClose }) => {
  const { t } = useTranslation()

  return (
    <div className="overlay">
      <div className="modal-container">
        <div className="modal-title-container">
          <h2 className="modal-title">{title || t('modal.defaultTitle')}</h2>
          <button className="modal-close-button" onClick={onClose}>
            <img style={{ width: '24px', height: 'auto' }} src={CloseIcon} alt="close" />
          </button>
        </div>
        {message && <p className="modal-message">{message}</p>}
        <div className="modal-actions">
          <Button variant="primary" size="compact" style={{ flexGrow: 1 }} onClick={onConfirm}>
            {t('modal.confirm')}
          </Button>
          <Button variant="secondary" size="compact" style={{ flexGrow: 1 }} onClick={onClose}>
            {t('modal.cancel')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
