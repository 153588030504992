import i18n from 'i18next'

const SEPARATOR = '_'

export function supportedLanguagesForCountryCode(countryCode: string) {
  return languagesForCountry[countryCode.toUpperCase()] || []
}

export function setLanguage(language: string, countryCode: string) {
  i18n.changeLanguage(`${language.toLowerCase()}${SEPARATOR}${countryCode.toUpperCase()}`)
}

export function getCurrentLanguage() {
  const language = i18n.language
  if (!language || language === 'default') {
    return
  }
  return language.split(SEPARATOR)[0]
}

export function initializeLanguage(countryCode: string, preferredLanguage?: string) {
  const language = getCurrentLanguage()
  const languages = supportedLanguagesForCountryCode(countryCode)
  if (languages.length === 0) {
    resetLanguage()
  }
  if (language && languages.includes(language)) {
    return
  }
  if (preferredLanguage && languages.includes(preferredLanguage.toLowerCase())) {
    setLanguage(preferredLanguage, countryCode)
  } else {
    setLanguage(languages[0], countryCode)
  }
}

export function resetLanguage() {
  i18n.changeLanguage('default')
}

const languagesForCountry: { [isoCode: string]: string[] } = {
  DE: ['de'],
  UK: ['en'],
}
