import axios, { AxiosRequestConfig } from 'axios'
import Config from '../config/Config'
import LocalStorageService from '../utils/LocalStorageService'
import { logoutInvalidSession } from '../features/authSlice/authSlice'

const APIClient = () => {
  const defaultOptions: AxiosRequestConfig = {
    baseURL: Config.BASE_URL,
  }

  const instance = axios.create(defaultOptions)

  instance.interceptors.request.use(
    (config) => {
      const token = LocalStorageService.getAccessToken()
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        const { store } = await import('../store')
        store.dispatch(logoutInvalidSession())
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export default APIClient()
