import { FC } from 'react'
import './styles.css'
import React from 'react'

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'primary' | 'secondary'
  size?: 'regular' | 'compact'
}

export const Button: FC<ButtonProps> = (props) => {
  const size = props.size || 'regular'
  return (
    <button
      className={`bmw-button bmw-button-${size} bmw-button-${props.variant}${props.className ? ` $ ${props.className}` : ''}`}
      style={props.style}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}
