import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { initializeLanguage, resetLanguage } from './utils/LanguageUtils'
import { useSelector } from 'react-redux'
import { RootState } from './store'

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'default',
      debug: import.meta.env.VITE_DEBUG === 'true',
      backend: {
        loadPath: '/locales/{{lng}}/translations.json',
      },
      detection: {
        order: ['localStorage'],
        caches: ['localStorage'],
      },
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      const { homeMarket } = useSelector((state: RootState) => state.userDetails)
      if (homeMarket) {
        initializeLanguage(homeMarket)
      } else {
        resetLanguage()
      }
    },
  )

export default i18n
