import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import vehiclesApi from '../../service/vehicles.api'
import { RootState } from '../../store'
import { VehicleInfo } from '../../types/api.types'

interface VehiclesState {
  vehicles?: VehicleInfo[]
  loading: boolean
  error: string | null
}

const initialState: VehiclesState = {
  loading: false,
  error: null,
}

export const fetchVehicles = createAsyncThunk<VehicleInfo[], void, { state: RootState }>(
  'vehicles/fetchVehicles',
  async (_, { rejectWithValue }) => {
    try {
      return await vehiclesApi.getVehicles()
    } catch (error) {
      console.error('Failed to fetch vehicles', error)
      return rejectWithValue('Failed to fetch vehicles')
    }
  },
)

const vehiclesSlice = createSlice({
  initialState,
  name: 'vehicles',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVehicles.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(fetchVehicles.fulfilled, (state, action) => {
      state.vehicles = action.payload
      state.loading = false
    })
    builder.addCase(fetchVehicles.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload as string
    })
  },
})

export const vehiclesReducer = vehiclesSlice.reducer
