import { FC } from 'react'
import { Trans } from 'react-i18next'
import './styles.css'

interface FormLabelProps {
  titleKey: string
  value?: string
  onEdit?: () => void
}

export const FormLabel: FC<FormLabelProps> = ({ titleKey, value, onEdit }) => {
  return (
    <div className="form-label">
      <div className="form-label-container">
        <div className="form-label-title">
          <Trans i18nKey={titleKey} />
        </div>
        <div className="form-label-value">{value}</div>
      </div>
      {onEdit && (
        <div>
          <button className="form-label-edit" onClick={() => onEdit()}>
            <Trans i18nKey={'edit'} />
          </button>
        </div>
      )}
    </div>
  )
}
