import { v4 as uuid } from 'uuid'
import Config from '../config/Config'

const GCDM_CLIENT = Config.GCDM_CLIENT
const GCDM_BASE_URL = Config.GCDM_BASE_URL
const REDIRECT_URI = Config.GCDM_REDIRECT_URI

type GcdmScope =
  | 'smacc'
  | 'vehicle_data'
  | 'perseus'
  | 'dlm'
  | 'svds'
  | 'profile'
  | 'vsapi'
  | 'remote_services'
  | 'cesim'
  | 'offline_access'
  | 'email'
  | 'fupo'
  | 'authenticate_user'
  | 'manage_credentials'
  | 'basic_profile'
  | 'full_profile'
  | 'manage_profile'
  | 'openid'

export interface GcdmState {
  state: string
  codeVerifier: string
  redirectUri: string
  loginUrl: string
}

export async function generateLoginState() {
  return await generateGcdmState(['authenticate_user', 'vehicle_data', 'remote_services', 'svds'])
}

export async function generateKeyCreationState() {
  return await generateGcdmState(['authenticate_user', 'vehicle_data', 'remote_services', 'svds'])
}

async function generateGcdmState(scopes: GcdmScope[]): Promise<GcdmState> {
  const state = uuid().toString()
  const minLength = 43
  const maxLength = 128
  const randomLength = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength
  const codeVerifier = generateRandomString(randomLength)
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  const queryParams: { [key: string]: string } = {
    client: GCDM_CLIENT,
    country: 'DE',
    language: 'en',
    scope: scopes.join('%20'),
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
    state: state,
    brand: 'bmw',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  }
  const query = Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&')
  return {
    state,
    codeVerifier,
    redirectUri: REDIRECT_URI,
    loginUrl: `${GCDM_BASE_URL}/oneid/login?${query}`,
  }
}

const generateCodeChallenge = async (codeVerifier: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const hash = await window.crypto.subtle.digest('SHA-256', data)

  const base64 = btoa(String.fromCharCode(...new Uint8Array(hash)))
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

const generateRandomString = (length: number): string => {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charactersLength)
    result += characters.charAt(randomIndex)
  }

  return result
}
