import { GeoLocation } from '../../types/api.types'
import { WizardVehicleConfiguration } from './WizardVehicleComponent'
import { WizardLocationConfiguration } from './WizardLocationComponent'
import { InfoBlockProps } from '../../components/InfoBlock/InfoBlock'

export type WizardStep = 'VEHICLE' | 'LOCATION' | 'OVERVIEW'

export const wizardStepOrder: WizardStep[] = ['VEHICLE', 'LOCATION', 'OVERVIEW']

export interface WizardStepProps<T> {
  provider: string
  configuration: T
  setConfiguration: (configuration: T) => void
  setFailureState: (state: InfoBlockProps) => void
}

export interface WizardConfiguration {
  vin?: string
  location?: GeoLocation
}

export function isFirstStep(step: WizardStep) {
  return step === wizardStepOrder[0]
}

export function isLastStep(step: WizardStep) {
  return step === wizardStepOrder[wizardStepOrder.length - 1]
}

export function getNextStep(step: WizardStep): WizardStep {
  if (isLastStep(step)) {
    return step
  }
  return wizardStepOrder[wizardStepOrder.indexOf(step) + 1]
}

export function getPreviousStep(step: WizardStep): WizardStep {
  if (isFirstStep(step)) {
    return step
  }
  return wizardStepOrder[wizardStepOrder.indexOf(step) - 1]
}

export function isStepValid(step: WizardStep, configuration: WizardConfiguration) {
  switch (step) {
    case 'VEHICLE':
      return isWizardVehicleConfigurationValid(toWizardVehicleConfiguration(configuration))
    case 'LOCATION':
      return isWizardLocationConfigurationValid(toWizardLocationConfiguration(configuration))
    case 'OVERVIEW':
      return true
  }
}

// Vehicle Configuration

export function toWizardVehicleConfiguration(configuration: WizardConfiguration): WizardVehicleConfiguration {
  return { vin: configuration.vin }
}

export function updateWithVehicleConfiguration(
  configuration: WizardConfiguration,
  stepConfiguration: WizardVehicleConfiguration,
) {
  return { ...configuration, vin: stepConfiguration.vin }
}

function isWizardVehicleConfigurationValid(configuration: WizardVehicleConfiguration) {
  return configuration.vin != null
}

// Location

export function toWizardLocationConfiguration(configuration: WizardConfiguration): WizardLocationConfiguration {
  return { location: configuration.location }
}

export function updateWithLocationConfiguration(
  configuration: WizardConfiguration,
  stepConfiguration: WizardLocationConfiguration,
) {
  return { ...configuration, location: stepConfiguration.location }
}

function isWizardLocationConfigurationValid(configuration: WizardLocationConfiguration) {
  return configuration.location != null
}
