import './styles.css'
import InfoIcon from '../../assets/images/info.svg'

export interface InfoBlockProps {
  title: string
  description: string
}

export const InfoBlock = ({ title, description }: InfoBlockProps) => (
  <div className="info-block">
    <img src={InfoIcon} alt="info" className="info-icon" />
    <div className="info-block-content">
      <p className="info-title">{title}</p>
      <p className="info-description">{description}</p>
    </div>
  </div>
)
