import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from '../../store'
import { VehiclesGrid, VehiclesGridItem } from '../../components/Vehicles/VehiclesGrid'
import './styles.css'
import { fetchVehicles } from '../../features/vehicleSlice/vehicleSlice'
import { useTranslation } from 'react-i18next'
import { WizardStepProps } from './WizardConfigurationUtil'
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { VehicleInfo } from '../../types/api.types'

export interface WizardVehicleConfiguration {
  vin?: string
}

const WizardVehicleComponent: FC<WizardStepProps<WizardVehicleConfiguration>> = ({
  configuration,
  setConfiguration,
  setFailureState,
}) => {
  const dispatch: AppDispatch = useDispatch<AppDispatch>()
  const { vehicles, loading, error } = useSelector((state: RootState) => state.vehicles)
  const { t } = useTranslation()

  useEffect(() => {
    if (!vehicles) {
      dispatch(fetchVehicles())
      return
    }
    const supportedVehicles = vehicles.filter(isVehicleSupported)
    if (supportedVehicles.length === 0) {
      setFailureState({ title: t('wizard.vehicle.none.title'), description: t('wizard.vehicle.none.description') })
    }
  }, [dispatch, vehicles, loading, setFailureState, t])

  const onSelection = (vin: string) => {
    setConfiguration({ vin })
  }

  const disabledHintForVehicle = (vehicle: VehicleInfo) => {
    if (isVehicleSupported(vehicle)) {
      return
    }
    if (!vehicle.isSupported) {
      return t('wizard.vehicle.disabledHint.notCompatible')
    } else if (!vehicle.isPrimaryUser) {
      return t('wizard.vehicle.disabledHint.notPrimary')
    } else {
      return ''
    }
  }

  const sortedVehicles = vehicles
    ?.map((vehicle) => {
      return { vehicle, disabledHint: disabledHintForVehicle(vehicle) }
    })
    .sort(({ disabledHint: d1 }, { disabledHint: d2 }) => {
      return d1 == null ? -1 : d2 == null ? 0 : 1
    })

  return (
    <>
      {error && <p>{error}</p>}
      <p className="wizard-header">{t('wizard.vehicle.description')}</p>
      {loading && <LoadingIndicator />}
      {vehicles && (
        <VehiclesGrid>
          {sortedVehicles?.map(({ vehicle, disabledHint }) => (
            <VehiclesGridItem
              key={vehicle.vin}
              vehicle={vehicle}
              isSelected={vehicle.vin === configuration.vin}
              onSelection={onSelection}
              disabledHint={disabledHint}
            />
          ))}
        </VehiclesGrid>
      )}
    </>
  )
}

function isVehicleSupported(vehicle: VehicleInfo) {
  return vehicle.isSupported && vehicle.isPrimaryUser
}

export default WizardVehicleComponent
