import React, { ReactNode } from 'react'
import './styles.css'
import FilledRadioIcon from '../../assets/images/radio-fill.svg'
import { VehicleInfo } from '../../types/api.types'
import { Checkbox } from '../Form/Checkbox'

interface VehiclesGridProps {
  children: ReactNode
}

interface VehicleCardProps {
  vehicle: VehicleInfo
  isSelected: boolean
  onSelection: (vin: string) => void
  showRadio?: boolean
  disabledHint?: string
  children?: ReactNode
}

export const VehiclesGrid: React.FC<VehiclesGridProps> = ({ children }) => (
  <div className="vehicles-grid-container">{children}</div>
)

export const VehiclesGridItem: React.FC<VehicleCardProps> = ({
  vehicle,
  isSelected,
  onSelection,
  showRadio = true,
  disabledHint,
  children,
}) => {
  const handleClick = () => {
    onSelection(vehicle.vin)
  }
  const disabled = disabledHint != null

  return (
    <div
      title={disabledHint}
      className={`vehicle-item ${disabled ? 'vehicle-item-disabled' : ''}`}
      onClick={disabled ? undefined : handleClick}
    >
      {!disabled &&
        (showRadio ? (
          <div className="vehicle-item-radio-button">
            {isSelected ? (
              <img src={FilledRadioIcon} alt="Selected" className="filled-radio" />
            ) : (
              <div className="unfilled-radio" />
            )}
          </div>
        ) : (
          <div className="vehicle-item-checkbox">
            <Checkbox isSelected={isSelected} />
          </div>
        ))}
      <img src={vehicle.imageUrl} alt={vehicle.model} className="vehicle-item-image" />
      <div className="vehicle-item-description">
        <h3 className="vehicle-item-title">{vehicle.model}</h3>
        <p className="vehicle-item-details">{vehicle.vin}</p>
        {children}
      </div>
    </div>
  )
}
