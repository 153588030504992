import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { generateLoginState } from '../../utils/GcdmLoginUtil'
import sessionApi from '../../service/session.api'
import LocalStorageService, { GcdmLoginState } from '../../utils/LocalStorageService'
import { GcdmLoginContext } from '../../types/api.types'
import Config from '../../config/Config'
import { resetUserDetails, setUserDetails } from '../userDetailsSlice/userDetailsSlice'

const GCDM_BASE_URL = Config.GCDM_BASE_URL

export interface AuthenticationState {
  isLoggedIn: boolean
  error: string | null
}

export const startLoginProcess = createAsyncThunk('auth/startLoginProcess', async () => {
  const { state, codeVerifier, redirectUri, loginUrl } = await generateLoginState()

  const gcdmState: GcdmLoginState = {
    state,
    codeVerifier,
    redirectUri,
    action: 'LOGIN',
  }
  LocalStorageService.storeGcdmLoginState(gcdmState)
  window.location.href = loginUrl
})

export const createSession = createAsyncThunk(
  'auth/createSession',
  async (loginContext: GcdmLoginContext, { dispatch, rejectWithValue }) => {
    try {
      const { accessToken, refreshToken, homeMarket, preferredLanguage } = await sessionApi.createSession(loginContext)
      LocalStorageService.storeTokens({ accessToken, refreshToken })
      dispatch(setUserDetails({ homeMarket, preferredLanguage }))
    } catch (error) {
      console.error('Error during session creation', error)
      return rejectWithValue('Login failed')
    }
  },
)

export const logoutUser = createAsyncThunk('auth/logoutUser', () => {
  LocalStorageService.clearSession()
  window.location.href = `${GCDM_BASE_URL}/oneid/logout.html?redirectUrl=${window.location.href}`
})

export const logoutInvalidSession = createAsyncThunk('auth/invalidSession', (_, { dispatch }) => {
  LocalStorageService.clearSession()
  dispatch(resetUserDetails())
})

const initialState: AuthenticationState = {
  isLoggedIn: LocalStorageService.getAccessToken() !== null,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(startLoginProcess.pending, (state) => {
        state.error = null
      })
      .addCase(createSession.pending, (state) => {
        state.error = null
      })
      .addCase(createSession.fulfilled, (state) => {
        state.isLoggedIn = true
      })
      .addCase(createSession.rejected, (state, action) => {
        state.error = action.payload as string
      })
      .addCase(logoutInvalidSession.fulfilled, (state) => {
        state.isLoggedIn = false
        state.error = null
      })
  },
})

export const authReducer = authSlice.reducer
