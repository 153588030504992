import React from 'react'
import './styles.css'
import { WizardConfiguration, WizardStep, wizardStepOrder } from './WizardConfigurationUtil'
import { Trans } from 'react-i18next'

interface WizardProgressBar {
  configuration: WizardConfiguration
  currentStep: WizardStep
  maxStep: WizardStep
  setCurrentStep: (step: WizardStep) => void
}

const WizardProgressBar: React.FC<WizardProgressBar> = ({ currentStep, maxStep, setCurrentStep }) => {
  const onSelection = (step: WizardStep) => {
    setCurrentStep(step)
  }
  const currentStepIndex = wizardStepOrder.indexOf(currentStep)
  const maxStepIndex = wizardStepOrder.indexOf(maxStep)

  return (
    <div className="wizard-progress-container">
      {wizardStepOrder.map((step, index) => (
        <SidebarItem
          key={step}
          index={index}
          step={step}
          currentStepIndex={currentStepIndex}
          maxStepIndex={maxStepIndex}
          titleKey={`wizard.steps.${step.toLowerCase()}`}
          enabled={index <= maxStepIndex}
          onSelection={onSelection}
        />
      ))}
    </div>
  )
}

interface SidebarItemProps {
  index: number
  step: WizardStep
  currentStepIndex: number
  maxStepIndex: number
  titleKey: string
  enabled: boolean
  onSelection: (step: WizardStep) => void
}

const SidebarItem = ({
  index,
  step,
  currentStepIndex,
  maxStepIndex,
  titleKey,
  enabled,
  onSelection,
}: SidebarItemProps) => {
  const isActiveOrCompleted = index <= maxStepIndex
  const active = index === currentStepIndex

  return (
    <button className="wizard-progress-item-container" disabled={!enabled} key={step} onClick={() => onSelection(step)}>
      <p className={'wizard-progress-item' + (active ? ' wizard-progress-item-active' : '')}>
        {index + 1} <Trans i18nKey={titleKey} />
      </p>
      <div
        className={
          'wizard-progress-bar-indicator' + (isActiveOrCompleted ? ' wizard-progress-bar-indicator-active' : '')
        }
      />
    </button>
  )
}

export default WizardProgressBar
