import { AxiosInstance } from 'axios'
import apiClient from './api-client'

interface ProviderInfo {
  id: string
  name: string
  clientId: string
}

export class ProviderAPI {
  private client: AxiosInstance

  constructor(axiosClient: AxiosInstance) {
    this.client = axiosClient
  }

  async getProvider(clientId: string): Promise<ProviderInfo> {
    try {
      const { data } = await this.client.get<ProviderInfo>(`/v1/provider?clientId=${clientId}`)
      return data
    } catch (error) {
      console.error('Failed to fetch provider', error)
      throw new Error('Failed to fetch provider')
    }
  }
}

export default new ProviderAPI(apiClient)
