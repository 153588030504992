import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiKeyMetadata, GcdmLoginContext, KeyCreationContext, PatchApiKey, PostApiKey } from '../../types/api.types'
import keysApi from '../../service/keys.api'
import LocalStorageService, { GcdmLoginState } from '../../utils/LocalStorageService'
import { generateKeyCreationState } from '../../utils/GcdmLoginUtil'

export interface ApiKeysState {
  keys: ApiKeyMetadata[]
  loading: boolean
  creatingKey: boolean
  error: string | null
}

export const generateApiKey = createAsyncThunk('apiKeys/generateApiKey', async (context: KeyCreationContext) => {
  const { state, codeVerifier, redirectUri, loginUrl } = await generateKeyCreationState()

  const gcdmState: GcdmLoginState = {
    state,
    codeVerifier,
    redirectUri,
    action: 'CREATE_KEY',
  }

  LocalStorageService.storeKeyCreationContext(context, state)
  LocalStorageService.storeGcdmLoginState(gcdmState, '/')
  window.location.href = loginUrl
})

export const fetchApiKeys = createAsyncThunk('auth/fetchApiKeys', async (_, { rejectWithValue }) => {
  try {
    return await keysApi.getApiKeys()
  } catch (error) {
    console.error('Failed to fetch API keys', error)
    return rejectWithValue('Failed to fetch API keys')
  }
})

export const createApiKey = createAsyncThunk<ApiKeyMetadata, GcdmLoginContext>(
  'apiKeys/createApiKey',
  async (loginContext) => {
    const keyCreationContext = LocalStorageService.getAndDeleteKeyCreationContext(loginContext.state)

    if (!keyCreationContext) {
      throw new Error('Key creation context not found')
    }

    const payload: PostApiKey = {
      ...loginContext,
      context: keyCreationContext,
    }

    const result = await keysApi.createApiKey(payload)
    if (result.redirectUrl) {
      const url = new URL(result.redirectUrl)
      url.searchParams.set('referenceid', keyCreationContext.referenceId)
      window.location.href = url.toString()
    }
    return result.apiKey
  },
)

export const updateApiKey = createAsyncThunk<ApiKeyMetadata, { id: string; context: PatchApiKey }>(
  'apiKeys/updateApiKey',
  async ({ id, context }) => {
    return keysApi.updateApiKey(id, context)
  },
)

export const deleteApiKey = createAsyncThunk<void, string>('apiKeys/deleteApiKey', async (id) => {
  await keysApi.deleteApiKey(id)
})

const initialState: ApiKeysState = {
  keys: [],
  loading: false,
  creatingKey: false,
  error: null,
}

const apiKeySlice = createSlice({
  name: 'apiKeys',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchApiKeys.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchApiKeys.fulfilled, (state, action) => {
        state.loading = false
        state.keys = action.payload
      })
      .addCase(fetchApiKeys.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Error fetching API keys'
      })
      .addCase(generateApiKey.rejected, (state, action) => {
        state.error = action.payload as string
      })

      .addCase(createApiKey.pending, (state) => {
        state.creatingKey = true
      })
      .addCase(createApiKey.fulfilled, (state, action) => {
        state.keys = [...state.keys, action.payload]
        state.creatingKey = false
      })
      .addCase(createApiKey.rejected, (state, action) => {
        state.error = action.error.message || 'Error creating API key'
        state.creatingKey = false
      })

      .addCase(updateApiKey.fulfilled, (state, action) => {
        state.keys = [...state.keys.filter((key) => key.id !== action.payload.id), action.payload]
      })
      .addCase(updateApiKey.rejected, (state, action) => {
        state.error = action.error.message || 'Error updating API key'
      })

      .addCase(deleteApiKey.fulfilled, (state, action) => {
        const apiKeyId = action.meta.arg
        state.keys = state.keys.filter((key) => key.id !== apiKeyId)
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.error = action.error.message || 'Error deleting API key'
      })
  },
})
export const apiKeyReducer = apiKeySlice.reducer
