import { FC, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, useMapEvents, useMap, Circle } from 'react-leaflet'
import L, { LatLngExpression, LeafletMouseEvent } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { GeoLocation } from '../../../types/api.types'

interface MapProps {
  location?: GeoLocation
  setLocation: (location: GeoLocation) => void
  locationRadius?: number
}

const Map: FC<MapProps> = ({ location, setLocation, locationRadius }) => {
  const zoom = 13
  const defaultCenter: LatLngExpression = [48.17691, 11.56013]

  const MapClickHandler = () => {
    useMapEvents({
      click(e: LeafletMouseEvent) {
        const newPosition = {
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        }
        setLocation(newPosition)
      },
    })
    return null
  }

  const MapEffect = () => {
    const map = useMap()

    useEffect(() => {
      if (location) {
        const center: LatLngExpression = [location.latitude, location.longitude]
        map.setView(center, map.getZoom())
      }
    }, [map])

    return null
  }

  const locationIcon = L.icon({
    iconUrl: '/images/location_pin.svg',
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  })

  return (
    <MapContainer
      center={location ? [location.latitude, location.longitude] : defaultCenter}
      zoom={zoom}
      style={{ height: '100%', width: '100%' }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {location && <Marker position={[location.latitude, location.longitude]} icon={locationIcon} />}
      {location && locationRadius && (
        <Circle
          center={[location.latitude, location.longitude]}
          radius={locationRadius}
          color="#1C69D4"
          weight={2}
          fillColor="white"
          fillOpacity={0.5}
        />
      )}
      <MapClickHandler />
      <MapEffect />
    </MapContainer>
  )
}

export default Map
