import { AxiosInstance } from 'axios'
import apiClient from './api-client'
import { VehicleInfo } from '../types/api.types'

export class VehiclesAPI {
  private client: AxiosInstance

  constructor(axiosClient: AxiosInstance) {
    this.client = axiosClient
  }

  async getVehicles(): Promise<VehicleInfo[]> {
    try {
      const { data } = await this.client.get<VehicleInfo[]>('/v1/vehicles')
      return data
    } catch (error) {
      console.error('Failed to fetch vehicles', error)
      throw new Error('Failed to fetch vehicles')
    }
  }
}

export default new VehiclesAPI(apiClient)
