import { useState, FC, useEffect } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import ApiKeyComponent from '../../components/ApiKeyComponent/ApiKeyComponent'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { deleteApiKey, fetchApiKeys } from '../../features/apiKeys/apiKeysSlice'
import { Button } from '../../components/Buttons/Button'
import { VehiclesGrid } from '../../components/Vehicles/VehiclesGrid'
import { Checkbox } from '../../components/Form/Checkbox'
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { InfoBlock } from '../../components/InfoBlock/InfoBlock'
import ConfirmModal from '../../components/Modals/Confirmation/ConfirmationModal'

const ApiKeysOverview: FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { keys, loading } = useSelector((state: RootState) => state.apiKeys)
  const { isLoggedIn } = useSelector((state: RootState) => state.auth)
  const [selectedApiKeys, setSelectedApiKeys] = useState<string[]>([])
  const [isShowingDeleteModal, setIsShowingDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const allSelected = keys.length > 0 && keys.every((key) => selectedApiKeys.includes(key.id))

  const { t } = useTranslation()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchApiKeys())
    }
  }, [dispatch, isLoggedIn])

  useEffect(() => {
    const keyIds = keys.map((key) => key.id)
    const newSelectedApiKeys = selectedApiKeys.filter((key) => keyIds.includes(key))
    if (selectedApiKeys.length !== newSelectedApiKeys.length) {
      setSelectedApiKeys(newSelectedApiKeys)
    }
  }, [keys, selectedApiKeys])

  const toggleApiKeySelection = (apiKeyId: string) => {
    const index = selectedApiKeys.indexOf(apiKeyId)
    if (index >= 0) {
      setSelectedApiKeys(selectedApiKeys.filter((_, i) => i !== index))
    } else {
      setSelectedApiKeys(selectedApiKeys.concat(apiKeyId))
    }
  }

  const handleDeleteConfirmed = () => {
    const deleteFunction = async () => {
      setDeleting(true)
      try {
        await Promise.all(selectedApiKeys.map((id) => dispatch(deleteApiKey(id))))
      } finally {
        setDeleting(false)
      }
    }
    deleteFunction()
    setIsShowingDeleteModal(false)
  }

  const handleSelectAllChange = () => {
    if (allSelected) {
      setSelectedApiKeys([])
    } else {
      setSelectedApiKeys(keys.map((key) => key.id))
    }
  }

  return (
    <>
      {isShowingDeleteModal && (
        <ConfirmModal
          title={t('keys.deleteConfirmation.title')}
          message={t('keys.deleteConfirmation.message')}
          onConfirm={handleDeleteConfirmed}
          onClose={() => setIsShowingDeleteModal(false)}
        />
      )}
      {!loading && keys.length === 0 ? (
        <InfoBlock title={t('keys.empty.title')} description={t('keys.empty.description')} />
      ) : (
        <>
          <p className="api-keys-header">{t('keys.description')}</p>
          <Button
            variant="primary"
            disabled={selectedApiKeys.length === 0}
            style={{ width: '100%' }}
            onClick={() => setIsShowingDeleteModal(true)}
          >
            {deleting ? <LoadingIndicator style={'INLINE'} /> : t('keys.button.delete')}
          </Button>
          <div className="api-keys-select-all-container">
            <button className="api-keys-select-all" onClick={handleSelectAllChange} disabled={keys.length === 0}>
              <Checkbox isSelected={allSelected} />
              {t('keys.button.selectAll')}
            </button>
          </div>
        </>
      )}
      {loading && <LoadingIndicator />}
      <VehiclesGrid>
        {keys.map((key) => (
          <ApiKeyComponent
            key={key.id}
            apiKey={key}
            isSelected={selectedApiKeys.includes(key.id)}
            onSelection={toggleApiKeySelection}
          />
        ))}
      </VehiclesGrid>
    </>
  )
}

export default ApiKeysOverview
