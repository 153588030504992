import { AxiosInstance } from 'axios'
import { ApiKeyCreated, ApiKeyMetadata, PatchApiKey, PostApiKey } from '../types/api.types'
import apiClient from './api-client'

export class KeysAPI {
  private client: AxiosInstance

  constructor(axiosClient: AxiosInstance) {
    this.client = axiosClient
  }

  async getApiKeys() {
    try {
      const { data } = await this.client.get<ApiKeyMetadata[]>('/v1/keys')
      return data
    } catch (error) {
      console.error('Failed to fetch API keys', error)
      throw new Error('Failed to fetch API keys')
    }
  }

  async createApiKey(body: PostApiKey) {
    try {
      const { data } = await this.client.post<ApiKeyCreated>('/v1/keys', body)
      return data
    } catch (error) {
      console.error(`Failed to post API key`, error)
      throw new Error('Failed to post API key')
    }
  }

  async updateApiKey(id: string, body: PatchApiKey) {
    try {
      const { data } = await this.client.patch<ApiKeyMetadata>(`/v1/keys/${id}`, body)
      return data
    } catch (error) {
      console.error(`Failed to patch API key ${id}`, error)
      throw new Error('Failed to patch API key')
    }
  }

  async deleteApiKey(id: string) {
    try {
      await this.client.delete(`/v1/keys/${id}`)
    } catch (error) {
      console.error(`Failed to delete API key ${id}`, error)
      throw new Error('Failed to delete API key')
    }
  }
}

export default new KeysAPI(apiClient)
