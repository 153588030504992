import axios from 'axios'
import { GeoLocation, Location, LocationWithAddress } from '../types/api.types'

const GEOCODE_URL = 'https://nominatim.openstreetmap.org'

class LocationUtil {
  private locationCache = new Map<string, string>()

  async addressForLocation(location: GeoLocation) {
    const locationKey = `${location.latitude},${location.longitude}`
    if (this.locationCache.has(locationKey)) {
      return this.locationCache.get(locationKey) || ''
    }
    try {
      const address = await reverseGeocode(location)
      this.locationCache.set(locationKey, address)
      return address
    } catch (error) {
      console.error('Failed to reverse geocode location', error)
      return ''
    }
  }
}

interface LocationSearchItem {
  lat: string
  lon: string
  addresstype: string
  name: string
  display_name: string
}

const reverseGeocode = async (location: GeoLocation): Promise<string> => {
  const response = await fetch(
    `${GEOCODE_URL}/reverse?format=json&lat=${location.latitude}&lon=${location.longitude}&addressdetails=1`,
  )
  const data = await response.json()

  const addressComponents = data.address || {}
  const country = addressComponents.country || ''
  const city = addressComponents.city || addressComponents.town || addressComponents.village || ''
  const street = addressComponents.road || ''
  const streetNumber = addressComponents.house_number || ''

  const formattedAddress = `${country}, ${city}, ${street} ${streetNumber}`.trim().replace(/,\s*,/, ',')

  return formattedAddress
}

export const autocompleteAddress = async (address: string): Promise<LocationWithAddress[]> => {
  try {
    const response = await axios.get<LocationSearchItem[]>(
      `${GEOCODE_URL}/search?format=json&q=${encodeURIComponent(address)}&limit=5`,
    )
    return response.data.map((item) => {
      return { latitude: parseFloat(item.lat), longitude: parseFloat(item.lon), address: item.display_name }
    })
  } catch (error) {
    console.error('Error fetching autocomplete suggestions:', error)
    return []
  }
}

export const formattedLocation = (location: Location) => {
  if (location.address) {
    return location.address
  }
  const latDegrees = Math.floor(Math.abs(location.latitude))
  const latMinSecs = (Math.abs(location.latitude) - latDegrees) * 60
  const latMinutes = Math.floor(latMinSecs)
  const latSeconds = Math.floor((latMinSecs - latMinutes) * 60)
  const formattedLat = `${latDegrees}° ${latMinutes}' ${latSeconds}'' ${location.latitude > 0 ? 'N' : 'S'}`

  const lonDegrees = Math.floor(Math.abs(location.longitude))
  const lonMinSecs = (Math.abs(location.longitude) - lonDegrees) * 60
  const lonMinutes = Math.floor(lonMinSecs)
  const lonSeconds = Math.floor((lonMinSecs - lonMinutes) * 60)
  const formattedLon = `${lonDegrees}° ${lonMinutes}' ${lonSeconds}'' ${location.longitude > 0 ? 'E' : 'W'}`

  return `${formattedLat}, ${formattedLon}`
}

export default new LocationUtil()
