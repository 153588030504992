import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LocalStorageService, { GcdmLoginState } from '../../utils/LocalStorageService'
import { createApiKey } from '../../features/apiKeys/apiKeysSlice'
import { createSession } from '../../features/authSlice/authSlice'
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator'

const OAuthCallback = () => {
  const { isLoggedIn, error: loginError } = useSelector((state: RootState) => state.auth)
  const { creatingKey } = useSelector((state: RootState) => state.apiKeys)
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [configuration, setConfiguration] = useState<{ code: string; state: GcdmLoginState }>()
  const [targetUrl, setTargetUrl] = useState<string>()
  const [action, setAction] = useState<'LOGIN' | 'CREATE_KEY'>()

  useEffect(() => {
    if (configuration) {
      return
    }
    const state = searchParams.get('state')
    const code = searchParams.get('code')
    if (!state || !code) {
      console.info('Missing OAuth state or code in URL')
      navigate('/')
      return
    }
    const storedState = LocalStorageService.getAndDeleteGcdmLoginStateForState(state)
    if (!storedState) {
      console.log(`No OAuth details stored for state ${state}`)
      navigate('/')
      return
    }
    const { location, ...loginState } = storedState
    setTargetUrl(location)
    setConfiguration({ code, state: loginState })
    setSearchParams(new URLSearchParams())
  }, [configuration, dispatch, navigate, searchParams, setSearchParams])

  useEffect(() => {
    if (!configuration) {
      return
    }
    const { action, ...loginState } = configuration.state
    const loginContext = { ...loginState, code: configuration.code }
    setAction(action)
    switch (action) {
      case 'LOGIN':
        dispatch(createSession(loginContext))
        break
      case 'CREATE_KEY':
        dispatch(createApiKey(loginContext))
        break
    }
  }, [configuration, dispatch])

  useEffect(() => {
    if (!targetUrl) {
      return
    }
    switch (action) {
      case 'LOGIN': {
        if (isLoggedIn || loginError) {
          navigate(targetUrl)
        }
        break
      }
      case 'CREATE_KEY': {
        if (!creatingKey) {
          navigate(targetUrl)
        }
        break
      }
    }
  }, [action, creatingKey, isLoggedIn, loginError, navigate, targetUrl])

  return <LoadingIndicator />
}

export default OAuthCallback
