import { AxiosInstance } from 'axios'
import { GcdmLoginContext, LoginResponse } from '../types/api.types'
import apiClient from './api-client'

class SessionAPI {
  private client: AxiosInstance

  constructor(axiosClient: AxiosInstance) {
    this.client = axiosClient
  }

  async createSession(context: GcdmLoginContext) {
    try {
      const { data } = await this.client.post<LoginResponse>('/v1/login', context)
      return data
    } catch (error) {
      console.error('Failed to create session', error)
      throw new Error('Failed to create session')
    }
  }
}

export default new SessionAPI(apiClient)
