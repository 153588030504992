import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { RootState, AppDispatch } from '../../store'
import { startLoginProcess } from '../../features/authSlice/authSlice'
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator'
import { InfoBlock } from '../InfoBlock/InfoBlock'
import { useTranslation } from 'react-i18next'

const ProtectedRoute: React.FC = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth)
  const { isCountrySupported } = useSelector((state: RootState) => state.userDetails)
  const { t } = useTranslation()

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(startLoginProcess())
    }
  }, [isLoggedIn, dispatch])

  if (!isLoggedIn) {
    return <LoadingIndicator />
  } else if (!isCountrySupported) {
    return <InfoBlock title={t('countryUnsupported.title')} description={t('countryUnsupported.description')} />
  }

  return <Outlet />
}

export default ProtectedRoute
