import './styles.css'
import Spinner from '../../assets/images/spinner.svg'

interface LoadingIndicatorProps {
  style?: 'DEFAULT' | 'INLINE'
}

export const LoadingIndicator = ({ style = 'DEFAULT' }: LoadingIndicatorProps) => {
  return (
    <div>
      <img
        className={'loading-indicator' + (style === 'INLINE' ? ' loading-indicator-inline' : '')}
        src={Spinner}
        alt="Loading..."
      />
    </div>
  )
}
