import React from 'react'
import CheckmarkIcon from '../../assets/images/checkmark_rg.svg'

interface CheckboxProps {
  isSelected: boolean
}

export const Checkbox: React.FC<CheckboxProps> = ({ isSelected }) => (
  <div className={`square-checkbox ${isSelected ? 'square-checkbox-selected' : ''}`}>
    {isSelected && <img src={CheckmarkIcon} alt="Selected" className="square-checkbox-checkmark" />}
  </div>
)
