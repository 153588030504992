import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import LocalStorageService from '../../utils/LocalStorageService'
import { initializeLanguage, resetLanguage, supportedLanguagesForCountryCode } from '../../utils/LanguageUtils'

interface UserDetails {
  homeMarket: string
  preferredLanguage: string
}

export interface UserDetailsState {
  homeMarket: string | null
  isCountrySupported: boolean
}

function isCountrySupported(homeMarket: string | null) {
  return homeMarket ? supportedLanguagesForCountryCode(homeMarket).length > 0 : true
}

export const setUserDetails = createAsyncThunk(
  'userDetails/set',
  async ({ homeMarket, preferredLanguage }: UserDetails, { dispatch }) => {
    dispatch(updateHomeMarket({ homeMarket }))
    initializeLanguage(homeMarket, preferredLanguage)
  },
)

export const resetUserDetails = createAsyncThunk('userDetails/reset', async (_, { dispatch }) => {
  dispatch(updateHomeMarket({ homeMarket: null }))
  resetLanguage()
})

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: () => {
    const homeMarket = LocalStorageService.getHomeMarket()
    return { homeMarket, isCountrySupported: isCountrySupported(homeMarket) }
  },
  reducers: {
    updateHomeMarket(state, action) {
      const homeMarket = action.payload.homeMarket
      state.homeMarket = homeMarket
      state.isCountrySupported = isCountrySupported(homeMarket)
      LocalStorageService.setHomeMarket(homeMarket)
    },
  },
})

const { updateHomeMarket } = userDetailsSlice.actions
export const userDetailsReducer = userDetailsSlice.reducer
