import { configureStore } from '@reduxjs/toolkit'
import { apiKeyReducer } from './features/apiKeys/apiKeysSlice'
import { authReducer } from './features/authSlice/authSlice'
import { vehiclesReducer } from './features/vehicleSlice/vehicleSlice'
import { userDetailsReducer } from './features/userDetailsSlice/userDetailsSlice'

export const store = configureStore({
  reducer: {
    apiKeys: apiKeyReducer,
    auth: authReducer,
    userDetails: userDetailsReducer,
    vehicles: vehiclesReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
