import { KeyCreationContext } from '../types/api.types'

const USER_SESSION_ACCESS_TOKEN = 'accessToken'
const USER_SESSION_REFRESH_TOKEN = 'refreshToken'
const GCDM_LOGIN_STATE = 'gcdmState'
const KEY_CREATION_CONTEXT = 'keyCreationContext'
const HOME_MARKET = 'homeMarket'

export interface GcdmLoginState {
  readonly state: string
  readonly codeVerifier: string
  readonly redirectUri: string
  readonly action: 'LOGIN' | 'CREATE_KEY'
}

class LocalStorageService {
  getAccessToken() {
    return localStorage.getItem(USER_SESSION_ACCESS_TOKEN)
  }

  storeTokens(tokens: { accessToken: string; refreshToken: string }) {
    localStorage.setItem(USER_SESSION_ACCESS_TOKEN, tokens.accessToken)
    localStorage.setItem(USER_SESSION_REFRESH_TOKEN, tokens.refreshToken)
  }

  clearSession() {
    localStorage.clear()
  }

  storeGcdmLoginState(state: GcdmLoginState, targetLocation?: string) {
    const location = targetLocation || window.location.pathname + window.location.search + window.location.hash
    localStorage.setItem(GCDM_LOGIN_STATE, JSON.stringify({ ...state, location }))
  }

  getAndDeleteGcdmLoginStateForState(state: string) {
    const gcdmStateRaw = localStorage.getItem(GCDM_LOGIN_STATE)
    if (!gcdmStateRaw) {
      return
    }
    localStorage.removeItem(GCDM_LOGIN_STATE)

    let gcdmState: GcdmLoginState & { location: string }
    try {
      gcdmState = JSON.parse(gcdmStateRaw)
    } catch {
      return
    }

    if (gcdmState.state !== state) {
      return
    }
    return gcdmState
  }

  storeKeyCreationContext(context: KeyCreationContext, state: string) {
    const keyCreationContext = { ...context, state }
    localStorage.setItem(KEY_CREATION_CONTEXT, JSON.stringify(keyCreationContext))
  }

  getAndDeleteKeyCreationContext(state: string) {
    const rawContext = localStorage.getItem(KEY_CREATION_CONTEXT)
    if (!rawContext) {
      return
    }
    localStorage.removeItem(KEY_CREATION_CONTEXT)

    let context: KeyCreationContext & { state?: string }
    try {
      context = JSON.parse(rawContext)
    } catch {
      return
    }
    if (context.state !== state) {
      return
    }
    delete context.state
    return context
  }

  getHomeMarket() {
    return localStorage.getItem(HOME_MARKET)
  }

  setHomeMarket(homeMarket: string | null) {
    if (homeMarket) {
      localStorage.setItem(HOME_MARKET, homeMarket)
    } else {
      localStorage.removeItem(HOME_MARKET)
    }
  }
}

export default new LocalStorageService()
