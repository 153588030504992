import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import NavBar from './components/NavBar/NavBar'
import OAuthCallback from './components/OAuthCallback/OAuthCallback'
import Wizard from './pages/WizardForm/WizardForm'
import { useTranslation } from 'react-i18next'
import './App.css'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import ApiKeysOverview from './pages/ApiKeys/ApiKeysOverview'

const App: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="app-component">
      <NavBar />
      <div className="app-header">
        <h2 className="service-name">{t('header.title')}</h2>
        <p className="service-description">{t('header.description')}</p>
      </div>
      <div className="main-content">
        <Routes>
          <Route path="/oauth/callback" element={<OAuthCallback />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<ApiKeysOverview />} />
            <Route path="/createapikey" element={<Wizard />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
