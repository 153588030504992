import { FC } from 'react'
import './styles.css'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguage, setLanguage, supportedLanguagesForCountryCode } from '../../utils/LanguageUtils'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

const Footer: FC = () => {
  const { t, ready } = useTranslation()
  const currentLanguage = getCurrentLanguage()
  const { homeMarket } = useSelector((state: RootState) => state.userDetails)

  const handleLanguageChange = (language: string) => {
    if (!homeMarket) {
      return
    }
    setLanguage(language, homeMarket)
  }

  const languages = homeMarket ? supportedLanguagesForCountryCode(homeMarket) : []
  const footerItems = t('footer.links', { returnObjects: true }) as unknown as [{ label: string; url: string }]

  return (
    <footer className="footer-component">
      <div className="information-div">
        <ul className="information-list">
          {languages.map((language) => (
            <li
              key={language}
              className={`information-list-item ${currentLanguage === language ? 'active' : ''}`}
              onClick={() => handleLanguageChange(language)}
            >
              {language.toUpperCase()}
            </li>
          ))}
        </ul>
      </div>
      <span className="footer-copyright">{t('footer.copyright', { year: new Date().getFullYear() })}</span>
      <div className="footer-links">
        {ready &&
          footerItems.map(({ label, url }) => (
            <a key={label} href={url}>
              {label}
            </a>
          ))}
      </div>
    </footer>
  )
}

export default Footer
