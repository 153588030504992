import { FC, useEffect, useState } from 'react'
import './styles.css'
import { Trans, useTranslation } from 'react-i18next'
import { WizardConfiguration, WizardStep } from './WizardConfigurationUtil'
import LocationUtil from '../../utils/LocationUtil'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { FormLabel } from '../../components/Form/FormLabel'

interface WizardOverviewComponentProps {
  provider: string
  configuration: WizardConfiguration
  onEdit: (step: WizardStep) => void
}

const WizardOverviewComponent: FC<WizardOverviewComponentProps> = ({ provider, configuration, onEdit }) => {
  const { t } = useTranslation()
  const { vehicles } = useSelector((state: RootState) => state.vehicles)
  const selectedVehicle = vehicles?.find((vehicle) => vehicle.vin === configuration.vin)

  const [address, setAddress] = useState<string | undefined>()
  useEffect(() => {
    const getLocation = async () => {
      const location = configuration.location
      if (location == null) {
        setAddress(undefined)
      } else {
        setAddress(await LocationUtil.addressForLocation(location))
      }
    }
    getLocation()
  }, [configuration.location])

  return (
    <>
      <p className="wizard-header">{t('wizard.overview.description')}</p>
      <div>
        <FormLabel titleKey={'wizard.overview.provider'} value={provider} />

        <FormLabel
          titleKey={'wizard.overview.vehicle'}
          value={selectedVehicle?.model}
          onEdit={() => onEdit('VEHICLE')}
        />
        <FormLabel titleKey={'wizard.overview.vin'} value={configuration.vin} />

        <FormLabel titleKey={'wizard.overview.location'} value={address} onEdit={() => onEdit('LOCATION')} />
      </div>

      <div className="wizard-overview-disclaimer">
        <Trans i18nKey={'wizard.overview.acknowledgement'}>{{ provider }}</Trans>
      </div>
    </>
  )
}

export default WizardOverviewComponent
